
@font-face {
  font-family: 'pretendard';
  src: 
  url('./assets/fonts/pretendard.woff2') format('woff2'),
  url('./assets/fonts/pretendard.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'pretendard_b';
  src: 
  url('./assets/fonts/pretendard_b.woff2') format('woff2'),
  url('./assets/fonts/pretendard_b.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'pretendard_sb';
  src: 
  url('./assets/fonts/pretendard_sb.woff2') format('woff2'),
  url('./assets/fonts/pretendard_sb.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'pretendard_m';
  src: 
  url('./assets/fonts/pretendard_m.woff2') format('woff2'),
  url('./assets/fonts/pretendard_m.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'sb_b';
  src: 
  url('./assets/fonts/sb_b.otf') format('opentype'),
  url('./assets/fonts/sb_b.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'sb_l';
  src: 
  url('./assets/fonts/sb_l.otf') format('opentype'),
  url('./assets/fonts/sb_l.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'sb_m';
  src: 
  url('./assets/fonts/sb_m.otf') format('opentype'),
  url('./assets/fonts/sb_m.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-animation {
  animation: slideIn 0.3s ease-out;
}

body {
  margin: 0;
  font-family: 'pretendard';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main_banners {
  display:  none !important;
}
@media (min-width: 1024px) {
  #root {
    overflow-y: hidden;
    background: #f7f7f7;
  }
  #topContainer{
    display: flex;
    width: 888px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

  }
  #bannerContainer{
    position: fixed;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 50px);


  }
  #mainFrame {
    width: 428px;
    margin: 0 auto;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: calc(40% + 100px);

    /*max-height: 99.9vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
*/
  }
  /*#mainFrame::-webkit-scrollbar {
    display: none; 
}*/
  #main_banners {
    display: flex !important;

  }
  #header {
    position: relative !important;
    padding: 0px !important;
    width: 150% !important;
    margin-left: -50px;
  }
  #header img{
    margin-left: 50px;
  }
  #header img#margin70{
    margin-left: 70px;
  }
  .modal {
    width: 428px !important;
    margin-left: -20px !important;
    left: auto!important;
    right: auto !important;
    left:
  }
  #padding0 {
    padding-top: 0px !important;
  }
  #padding15 {
    padding-top: 15px !important;
  }

}


textarea, input {
  outline: NONE;
  box-shadow: NONE;
}

code {
  font-family: 'pretendard', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 모달이 열릴 때 아래에서 위로 올라오는 애니메이션 */
@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 모달이 닫힐 때 위에서 아래로 내려가는 애니메이션 */
@keyframes slide-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

/* 모달이 열릴 때 적용될 클래스 */
.modal-enter {
  animation: slide-up 0.35s ease-out forwards;
}

/* 모달이 닫힐 때 적용될 클래스 */
.modal-exit {
  animation: slide-down 0.35s ease-out forwards;
}
